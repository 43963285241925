import { render, staticRenderFns } from "./introduction.vue?vue&type=template&id=9602844e&scoped=true&"
import script from "./introduction.vue?vue&type=script&lang=js&"
export * from "./introduction.vue?vue&type=script&lang=js&"
import style0 from "./introduction.vue?vue&type=style&index=0&id=9602844e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9602844e",
  null
  
)

export default component.exports