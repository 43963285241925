<!--
 * @Author: your name
 * @Date: 2021-01-05 09:45:31
 * @LastEditTime: 2021-01-09 16:17:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \guanwang\zhongwang-index-vue\src\views\pages\introduction\introduction.vue
-->
<template>
  <div class="intBody">
    <banner bg-name="banner.png" desc-type="3714.png"></banner>
    <div class="nav">
      <ul class="nav-list">
        <li class="nav-item" :class="{ active: activeIndex == 0 }" @click="handleClickNavItem(0)">
          公司介绍
        </li>
        <li class="nav-item" :class="{ active: activeIndex == 1 }" @click="handleClickNavItem(1)">
          愿景使命
        </li>
        <li class="nav-item" :class="{ active: activeIndex == 2 }" @click="handleClickNavItem(2)">
          发展历程
        </li>
        <li class="nav-item" :class="{ active: activeIndex == 3 }" @click="handleClickNavItem(3)">
          企业文化
        </li>
        <li class="nav-item" :class="{ active: activeIndex == 4 }" @click="handleClickNavItem(4)">
          办公地点
        </li>
      </ul>
      <div class="nav-bar">
        <div class="nav-slider" :style="{ left: sliderLeft }"></div>
      </div>
    </div>
    <div class="introduNav">
      <div class="imgInt" id="intid">
        <img src="~@/assets/img/3719.png" />
      </div>
      <div class="intRes">
        <div class="intResLeft">
          <img src="~@/assets/img/36.png" />
        </div>
        <div style="width: 600px">
          <p class="intResRh">厚德 责任</p>
          <p class="intResRt">
            专业从事信息化、数字化技术研发，包括软件开发与一体化解决方案整合，主要聚焦EHS（安全、环保、职业健康）智慧管控；目前公司有全职技术人员100余人，包括IT技术和相关领域的业务专家团队，行业理解深刻、持续技术前沿追踪、开发实力强大。成熟案例应用广泛，多个行业用户成功实施上线并运转良好，产品包括：EHS云管家，智慧园区管理平台、隐患排查与风险管控系统等
          </p>
        </div>
      </div>
    </div>
    <div style="height: 40px;" id="missid"></div>
    <div class="intMission">
      <div class="intMission1">
        <div class="intMissionTop">
          <img src="~@/assets/img/3723.png" alt="" />
        </div>
        <div class="intMissionBott">
          <p class="intMissionBottTitle">用户为本，科技向善</p>
          <p class="intMissionBottNr">
            一切以用户价值为依归，将社会责任融入产品及服务之中；推动科技创新与文化传承，助力各行各业升级，促进社会的可持续发展。
          </p>
          <div />
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 10px" id="timeid"></div>
    <div class="intDev">
      <img src="~@/assets/img/3927.png" alt="" />
    </div>
    <div class="intTime">
      <div class="intTimeCore">
        <span :class="istime ? 'time' : 'timeNo'">2020</span>
        <span :class="istime2 ? 'time2' : 'timeNo2'">2019</span>
        <span :class="istime3 ? 'time3' : 'timeNo3'">2018</span>
        <div @click="clickTime" :class="istime ? 'intTimeCoreImg' : 'intTimeCore1'"></div>
        <div @click="clickTime2" :class="istime2 ? 'intTimeCoreImg2' : 'intTimeCore2'"></div>
        <div @click="clickTime3" :class="istime3 ? 'intTimeCoreImg3' : 'intTimeCore3'"></div>
        <div class="intTimeContent" v-show="istime">
          <div>
            <p class="intTimeContent1">2020年3月</p>
            <p>开始研发EHS云管家系统3.0</p>
          </div>
          <div style="margin-top: 30px">
            <p class="intTimeContent1">2020年6月</p>
            <p>EHS云管家系统3.0正式上线</p>
          </div>
          <div style="margin-top: 30px">
            <p class="intTimeContent1">2020年8月</p>
            <p>开始研发众望生态圈-智慧园区管理平台</p>
          </div>
        </div>
        <div class="intTimeContent" v-show="istime2">
          <div>
            <p class="intTimeContent1">2019年5月</p>
            <p>开始研发EHS云管家系统2.0</p>
          </div>
          <div style="margin-top: 30px">
            <p class="intTimeContent1">2019年10月</p>
            <p>EHS云管家系统2.0正式上线</p>
          </div>
        </div>
        <div class="intTimeContent" v-show="istime3">
          <div>
            <p class="intTimeContent1">2018年1月</p>
            <p>公司在成都成立</p>
          </div>
          <div style="margin-top: 30px">
            <p class="intTimeContent1">2018年2月</p>
            <p>开始研发并联评价云平台</p>
          </div>
          <div style="margin-top: 30px">
            <p class="intTimeContent1">2018年5月</p>
            <p>开始研发EHS云管家系统</p>
          </div>
        </div>
        <div class="intTimeContentTwo" v-show="istime3">
          <div>
            <p class="intTimeContent1">2018年7月</p>
            <p>并联评价云平台正式上线</p>
          </div>
          <div style="margin-top: 30px">
            <p class="intTimeContent1">2018年10月</p>
            <p>EHS云管家系统正式上线</p>
          </div>
        </div>
      </div>
    </div>
    <div class="intCulture" id="cultureid">
      <img src="~@/assets/img/3730.png" alt="" />
    </div>
    <div class="intCulture1">
      <div style="position: relative">
        <div class="intCultureChi"></div>
        <p class="intCultureP">科学发展八字方针</p>
        <p class="intCultureP1">责任 规范 诚信 真相</p>
      </div>
      <div style="position: relative">
        <div class="intCultureChi2"></div>
        <p class="intCultureP2">健康成长八字方针</p>
        <p class="intCultureP3">阳光 正气 奉献 和谐</p>
      </div>
      <div style="position: relative">
        <div class="intCultureChi3"></div>
        <p class="intCultureP4">四项基本原则</p>
        <p class="intCultureP5">事件负责制 超级扁平化 无边界 并联评价</p>
      </div>
    </div>
    <div class="intWork" id="mapid">
      <img src="~@/assets/img/3902.png" alt="" />
    </div>
    <div class="intMap">
      <baidu-map class="map-view" scroll-wheel-zoom :center="mapCenter1" :zoom="16">
        <bm-marker :position="mapCenter1" @click="infoWindowOpen">
          <bm-info-window :show="showMap" @close="infoWindowClose" @open="infoWindowOpen"
            >四川省成都市青羊区青龙街51号倍特康派大厦9楼
          </bm-info-window>
        </bm-marker>
        <bm-view class="map-view"></bm-view>

        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          showAddressBar
          autoLocation
        ></bm-geolocation>
      </baidu-map>
    </div>
  </div>
</template>
<script src="https://unpkg.com/vue-baidu-map"></script>
<script>
import Banner from '@/components/Banner';

export default {
  components: {
    Banner
  },

  data() {
    return {
      istime: true,
      istime2: false,
      istime3: false,
      sliderLeft: '70px',
      activeIndex: 0,
      curHeight: 0, //当前所需屏幕高度
      top: '',
      rouId: '',
      mapCenter1: { lng: 104.06986, lat: 30.676778 },
      mapZoom: 15,
      showMap: false
    };
  },
  methods: {
    clickTime() {
      this.istime = true;
      this.istime2 = false;
      this.istime3 = false;
    },
    clickTime2() {
      this.istime2 = true;
      this.istime = false;
      this.istime3 = false;
    },
    clickTime3() {
      this.istime3 = true;
      this.istime2 = false;
      this.istime = false;
    },
    handleClickNavItem(index) {
      this.activeIndex = index;
      let str = '';
      switch (index) {
        case 0:
          str = '70px';
          document.querySelector('#intid').scrollIntoView({
            behavior: 'smooth'
          });
          break;
        case 1:
          str = '310px';
          document.querySelector('#missid').scrollIntoView({
            behavior: 'smooth'
          });
          break;
        case 2:
          str = '550px';
          document.querySelector('#timeid').scrollIntoView({
            behavior: 'smooth'
          });
          break;
        case 3:
          str = '790px';
          document.querySelector('#cultureid').scrollIntoView({
            behavior: 'smooth'
          });
          break;
        case 4:
          str = '1030px';
          document.querySelector('#mapid').scrollIntoView({
            behavior: 'smooth'
          });
          break;
        default:
          break;
      }
      // this.sliderLeft = str;
    },
    scrollHandle(e) {
      this.top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      if (this.top < 950) {
        this.activeIndex = 0;
        this.sliderLeft = '70px';
      } else if (this.top >= 1301 && this.top <= 1700) {
        this.activeIndex = 1;
        this.sliderLeft = '310px';
      } else if (this.top >= 1701 && this.top <= 2400) {
        this.activeIndex = 2;
        this.sliderLeft = '550px';
      } else if (this.top >= 2401 && this.top <= 3600) {
        this.activeIndex = 3;
        this.sliderLeft = '790px';
      } else if (this.top >= 3601) {
        this.activeIndex = 4;
        this.sliderLeft = '1030px';
      }
    },
    infoWindowClose() {
      this.showMap = false;
    },
    infoWindowOpen() {
      this.showMap = true;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandle); //绑定页面滚动事件
    this.$nextTick(() => {
      this.rouId = +this.$route.query.params;
      this.handleClickNavItem(this.rouId);
      this.activeIndex = this.rouId || 0;
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandle);
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal.query.params !== oldVal.query.params) {
            this.rouId = +newVal.query.params;
            this.handleClickNavItem(this.rouId);
          }
          return newVal;
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>
.intBody {
  width: 100%;
  // height: 100%;
  .nav {
    position: sticky;
    top: 0;
    margin: 30px auto 0;
    background-color: #fff;
    height: 48px;
    line-height: 48px;
    .nav-bar {
      position: relative;
      width: 1200px;
      height: 2px;
      // margin-right: 28px;
      margin: -2px auto 0;
      background-color: #f4f4f4;
      border-radius: 9px;
      .nav-slider {
        position: absolute;
        // top: 10px;
        height: 2px;
        width: 100px;
        border-radius: 9px;
        background-color: #1d7df8;
        transition: all 0.2s ease-out;
      }
    }

    .nav-list {
      display: flex;
      margin: 30px auto 0;
      width: 1200px;
      // flex-direction: column;
      // justify-content: space-between;
      // height: 3px;
      .nav-item {
        display: flex;
        flex: 1;
        color: #666666;
        // margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.2s ease-out;
        &:hover {
          font-size: 18px;
          color: #1d7df8;
        }
        &:nth-child(1) {
          align-items: center;
          justify-content: center;
        }
        &:nth-child(2) {
          align-items: center;
          justify-content: center;
        }

        &:nth-child(3) {
          align-items: center;
          justify-content: center;
        }
        &:nth-child(4) {
          align-items: center;
          justify-content: center;
        }
        &:nth-child(5) {
          align-items: center;
          justify-content: center;
        }
      }
      .active {
        font-size: 18px;
        color: #1d7df8;
      }
    }
  }
  .introduNav {
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;

    .imgInt {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 434px;
        height: 74px;
      }
    }
    .intRes {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      // height: 500px;
      margin: 40px 0 140px 0;
      .intResLeft {
        img {
          width: 419px;
          height: 335px;
        }
      }
      .intResRh {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        font-size: 24px;
        margin-bottom: 32px;
      }
      .intResRh:after {
        float: right;
        margin: -50px 480px 0 0;
        content: url('~@/assets/images/titleImg.png');
        width: 40px;
        height: 40px;
      }
      .intResRt {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .intMission {
    width: 100%;
    // box-sizing: border-box;
    background: url('~@/assets/img/37.png') center no-repeat;
    background-size: cover;
    height: 707px;
    .intMission1 {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      .intMissionTop {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        img {
          width: 466px;
          height: 74px;
        }
      }
    }
    .intMissionBott {
      width: 100%;
      height: 327px;
      background: rgba(0, 0, 0, 0.5);

      .intMissionBottTitle {
        display: inline-block;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: 101px 0 35px 65px;
      }
      .intMissionBottNr {
        font-weight: 400;
        font-size: 16px;
        font-family: Microsoft YaHei;
        color: #ffffff;
        margin-left: 65px;
      }
      div {
        width: 77px;
        height: 4px;
        background-color: #1d7df8;
        margin: 39px 0 0 65px;
      }
    }
  }
  .intDev {
    width: 100%;
    height: 74px;
    margin: 100px 0 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 518px;
      height: 74px;
    }
  }
  .intTime {
    width: 100%;
    background: url('~@/assets/img/3928.png') center no-repeat;
    background-size: cover;
    height: 544px;
    .intTimeCore {
      position: relative;
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .intTimeCore1 {
        position: absolute;
        top: 16px;
        width: 35px;
        height: 35px;
        border-radius: 17px;
        cursor: pointer;
        transition: all 0.5s;
        background-color: #ffffff;
      }
      .intTimeCoreImg {
        position: absolute;
        width: 82px;
        height: 82px;
        background: url('~@/assets/img/3929.png') center no-repeat;
        transition: all 0.5s;
        background-size: cover;
      }
      .intTimeCore2 {
        position: absolute;
        left: 500px;
        top: 57px;
        width: 35px;
        height: 35px;
        border-radius: 40px;
        background-color: #ffffff;
        cursor: pointer;
        transition: opacity 1s linear;
        transition: all 0.5s;
      }
      .intTimeCoreImg2 {
        position: absolute;
        left: 500px;
        top: 37px;
        width: 82px;
        height: 82px;
        background: url('~@/assets/img/3929.png') center no-repeat;
        background-size: cover;
        transition: all 0.5s;
      }
      .intTimeCore3 {
        position: absolute;
        left: 1000px;
        top: 96px;
        width: 35px;
        height: 35px;
        border-radius: 40px;
        background-color: #ffffff;
        cursor: pointer;
        transition: all 0.5s;
      }
      .intTimeCoreImg3 {
        position: absolute;
        left: 1000px;
        top: 77px;
        width: 82px;
        height: 82px;
        background: url('~@/assets/img/3929.png') center no-repeat;
        transition: all 0.5s;
        background-size: cover;
      }
      .intTimeContent {
        position: absolute;
        left: 30px;
        top: 100px;
        width: 300px;
        margin-top: 48px;
        p {
          color: #ffffff;
          font-size: 16px;
        }
        .intTimeContent1 {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
      .intTimeContentTwo {
        position: absolute;
        left: 350px;
        top: 100px;
        width: 300px;
        margin-top: 48px;
        p {
          color: #ffffff;
          font-size: 16px;
        }
        .intTimeContent1 {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
      .time {
        position: absolute;
        top: -40px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .timeNo {
        position: absolute;
        top: -10px;
        left: -5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        color: #666666;
      }
      .time2 {
        position: absolute;
        top: -10px;
        left: 500px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .timeNo2 {
        position: absolute;
        top: 30px;
        left: 495px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        color: #666666;
      }
      .time3 {
        position: absolute;
        top: 30px;
        left: 1000px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .timeNo3 {
        position: absolute;
        top: 70px;
        left: 995px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        color: #666666;
      }
    }
  }

  .intCulture {
    width: 100%;
    height: 247px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 440px;
      height: 74px;
    }
  }
  .intCulture1 {
    width: 1200px;
    background: url('~@/assets/img/3937png.png') center no-repeat;
    background-size: cover;
    height: 505px;
    margin: 0 auto;
    p {
      font-family: Microsoft YaHei;
      color: #ffffff;
      font-size: 16px;
    }
    .intCultureChi {
      position: absolute;
      top: 100px;
      left: 450px;
      width: 30px;
      height: 4px;
      background-color: #1d7df8;
    }
    .intCultureP {
      position: absolute;
      top: 120px;
      left: 450px;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .intCultureP1 {
      position: absolute;
      top: 150px;
      left: 450px;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .intCultureChi2 {
      position: absolute;
      top: 210px;
      left: 450px;
      width: 30px;
      height: 4px;
      background-color: #1d7df8;
    }
    .intCultureP2 {
      position: absolute;
      top: 230px;
      left: 450px;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .intCultureP3 {
      position: absolute;
      top: 260px;
      left: 450px;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .intCultureChi3 {
      position: absolute;
      top: 320px;
      left: 450px;
      width: 30px;
      height: 4px;
      background-color: #1d7df8;
    }
    .intCultureP4 {
      position: absolute;
      top: 340px;
      left: 450px;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .intCultureP5 {
      position: absolute;
      top: 370px;
      left: 450px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .intWork {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    img {
      width: 434px;
      height: 74px;
    }
  }
  .intMap {
    width: 1200px;
    height: 710px;
    margin: 0 auto 90px;
    .map-view {
      width: 1200px;
      height: 710px;
    }
  }
}
</style>
